export const durationFilters: {
  title: string;
  value: string;
  data: {
    [key: string]: {
      min: number;
      max: number;
    };
  };
}[] = [
  {
    title: 'Less than 2 weeks',
    value: '1-14 days',
    data: {
      day: {
        min: 1,
        max: 14,
      },
      week: {
        min: 1,
        max: 2,
      },
    },
  },
  {
    title: '2-4 weeks',
    value: '14-30 days',
    data: {
      day: {
        min: 14,
        max: 30,
      },
      week: {
        min: 2,
        max: 4,
      },
    },
  },
  {
    title: '1-4 months',
    value: '30-120 days',
    data: {
      day: {
        min: 30,
        max: 120,
      },
      week: {
        min: 4,
        max: 16,
      },
      month: {
        min: 1,
        max: 4,
      },
    },
  },
  {
    title: '4-6 months',
    value: '120-180 days',
    data: {
      day: {
        min: 120,
        max: 180,
      },
      week: {
        min: 16,
        max: 24,
      },
      month: {
        min: 4,
        max: 6,
      },
    },
  },
  {
    title: '6-12 months',
    value: '180-360 days',
    data: {
      day: {
        min: 180,
        max: 360,
      },
      week: {
        min: 24,
        max: 48,
      },
      month: {
        min: 6,
        max: 12,
      },
    },
  },
  {
    title: 'More than a year',
    value: '390-1080 days',
    data: {
      day: {
        min: 390,
        max: 1080,
      },
      week: {
        min: 55,
        max: 154,
      },
      month: {
        min: 13,
        max: 36,
      },
    },
  },
];

export const ageFilters: {
  title: string;
  value: string;
  data: {
    min: number;
    max: number;
  };
}[] = [
  {
    title: 'Under 18 years old',
    value: '0-17',
    data: {
      min: 0,
      max: 17,
    },
  },
  {
    title: '18-30 years old',
    value: '18-30',
    data: {
      min: 18,
      max: 30,
    },
  },
  {
    title: '31-35 years old',
    value: '31-35',
    data: {
      min: 31,
      max: 35,
    },
  },
  {
    title: '36-45 years old',
    value: '36-45',
    data: {
      min: 36,
      max: 45,
    },
  },
  {
    title: 'Over 45 years old',
    value: '46-100',
    data: {
      min: 46,
      max: 100,
    },
  },
];
