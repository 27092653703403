import React, { useEffect, useState } from 'react';
import { GImage } from '../../g-image';
import { GStarRating } from '../../g-star-rating';
import Favorite from '../../favorite-button';
import Link from 'next/link';
import { BasicTrip, Promotion, TripPrice } from '@/Lib/types/trip';
import { useLocation } from '@/Lib/hooks/useLocation';
import { round } from '@/Lib/helpers/round-number';
import { useQuery } from '@apollo/client';
import { GET_TRIP_PRICE } from '@/Lib/graphql/queries/trip.query';
import TourIconSVG from '@/Components/search-panel/search-panel-tabs/tour-icon-svg';
import { Price } from './price';
import dynamic from 'next/dynamic';

const DynamicVideo = dynamic(() => import('@/Components/videoPlayer/card'), {
  ssr: false,
});
import { useAtomValue } from 'jotai';
import { promotionsAtom } from '@/State/global/global.store';
import { currencySymbols } from '../../../data/currency';

interface ProductCardProps {
  trip: BasicTrip;
  clickProductCard?: any;
}

function ProductCard({
  trip,
  clickProductCard,
}: ProductCardProps): JSX.Element {
  const { data: tripData } = useQuery<{ trip: TripPrice }>(GET_TRIP_PRICE, {
    variables: {
      id: trip.id,
    },
  });

  const { currency } = useLocation();
  const [discount, setDiscount] = useState<number>(0);
  const [reducedDeposit, setReducedDeposit] = useState<number>(0);

  const { promotions: appliedPromotions, getDiscountAndReducedDeposit } =
    useAtomValue(promotionsAtom);

  useEffect(() => {
    const d = getDiscountAndReducedDeposit
      ? getDiscountAndReducedDeposit(
          trip.promotions,
          appliedPromotions as Promotion[],
          trip,
          trip.prices[currency],
          tripData?.trip.deposit[currency] || 0,
          currency
        )
      : { discount: 0, reducedDeposit: 0 };

    setDiscount(d.discount);
    setReducedDeposit(d.reducedDeposit);
  }, [
    appliedPromotions,
    currency,
    getDiscountAndReducedDeposit,
    trip,
    tripData?.trip.deposit,
  ]);

  let hasAgeRange = trip.age.min || trip.age.max || null;

  return (
    <article
      className="group w-full lg:w-auto bg-light-600 rounded-md overflow-hidden border border-light-700 relative cursor-pointer"
      style={{ boxShadow: '2px 2px 6px 0 rgb(0 0 0 / 15%)' }}
    >
      {trip.tag ? (
        <div className="bg-gray-900 bg-opacity-60 py-1 px-3 font-bold rounded-full text-2xs leading-3.5 text-light-700 absolute top-3.5 left-3.5 z-20">
          {trip.tag}
        </div>
      ) : null}
      <Link href={trip.url}>
        <a className="flex flex-col justify-between" onClick={clickProductCard}>
          <div>
            <div className="relative bg-light-700 h-48 overflow-hidden">
              {!trip.bannerVideo && trip.bannerImg && (
                <GImage
                  path={trip.bannerImg}
                  transformation="trip-cards"
                  alt={trip.tripName}
                  height="100%"
                  width="100%"
                  hasLoadingBackground
                  useGallery={true}
                />
              )}

              {trip.bannerVideo && (
                <>
                  <div className="md:hidden w-full h-full">
                    <GImage
                      path={trip.bannerImg}
                      transformation="trip-cards"
                      alt={trip.tripName}
                      height="100%"
                      width="100%"
                      hasLoadingBackground
                      useGallery={true}
                    />
                  </div>
                  <div className="hidden md:block w-full h-full">
                    <DynamicVideo
                      src={trip.bannerVideo}
                      poster={trip.bannerVideoCover}
                    />
                  </div>
                </>
              )}

              <Favorite
                deposit={
                  tripData?.trip?.deposit[currency]
                    ? tripData?.trip?.deposit[currency] - reducedDeposit
                    : 0
                }
                price={trip.prices[currency] - discount}
                revenue={trip.prices[currency] - discount}
                type="productcard"
                trip={trip}
                originalPrice={trip.prices[currency]}
                originalDeposite={
                  tripData?.trip?.deposit[currency]
                    ? tripData?.trip?.deposit[currency]
                    : 0
                }
              />
              {!trip.bannerVideo && (
                <div
                  className={
                    'absolute inset-0 z-10 transition-colors duration-300 bg-dark-900 bg-opacity-0 group-hover:bg-opacity-20 '
                  }
                ></div>
              )}
            </div>
            <div className="p-3.5 lg:p-5 min-h-10 box-border">
              <div className="flex flex-wrap text-dark-900 capitalize font-normal leading-5 text-2xs gap-x-4 whitespace-nowrap">
                <div className="flex items-center">
                  <i className="icon-list mr-1.5 text-sm"></i> {trip.division}
                </div>
                <div className="flex items-center">
                  <i className="icon-location mr-1.5 text-sm"></i>
                  {trip.destination}
                </div>
                {trip.division === 'Tour' && (
                  <div className="flex items-center">
                    <div className="mr-1.5">
                      <TourIconSVG isSmall />
                    </div>
                    {trip.partner?.name ?? ''}
                  </div>
                )}
              </div>
              <h3 className="text-lg font-semibold lg:text-xl lg:font-semibold lg:leading-6 my-2 text-dark-800 group-hover:underline">
                {trip.tripName}
              </h3>
              <div className="text-sm leading-3 font-semibold flex items-center flex-wrap text-dark-900 space-x-1.5">
                {trip.reviews?.count ? (
                  <React.Fragment>
                    <GStarRating rating={Number(trip.reviews?.rating)} />
                    <div className="font-semibold text-sm leading-4">
                      {round(Number(trip.reviews?.rating), 1)}
                    </div>
                    <div className="text-xs font-normal">
                      ({trip.reviews?.count} reviews)
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
            <div className="text-2xs leading-3 font-semibold flex items-center flex-wrap text-dark-900 gap-1 px-4 lg:px-5 mb-2">
              {hasAgeRange !== null && (
                <div className="py-1 px-1.5 rounded-full font-bold bg-purple-700 text-purple-900">
                  {trip.age.min}
                  {trip.age.max ? '-' + trip.age.max : '+'} y/o
                </div>
              )}
              <div className="py-1 px-1.5 rounded-full font-bold bg-teal-500 text-teal-900">
                {trip.duration.min === trip.duration.max
                  ? `${trip.duration.min} ${trip.durationType}s`
                  : `${trip.duration.min}-${trip.duration.max} ${trip.durationType}s`}
              </div>
              {discount ? (
                <div className="py-1 px-1.5 rounded-full font-bold bg-[#FEF1F0] text-[#FE5754]">
                  {currencySymbols[currency] + discount} off
                </div>
              ) : null}
            </div>
          </div>
          <div className="border-t border-light-700 flex jsutify-between p-4 lg:pt-6 lg:px-5 lg:pb-5">
            <Price discount={discount} trip={trip} />
          </div>
        </a>
      </Link>
    </article>
  );
}

function ProductCardSkeleton() {
  return (
    <article className="bg-light-600 rounded-md shadow-md overflow-hidden border border-light-700 relative animate-pulse max-w-xs">
      <div className="bg-light-900 h-50 overflow-hidden animate-pulse"></div>
      <div className="p-4 min-h-10 box-border lg:p-5">
        <div className="h-6 bg-light-900 my-1"></div>
        <div className="my-1 h-5 bg-light-900"></div>
        <div className="my-1 h-5 w-12 bg-light-900"></div>
      </div>
      <div className="border-t border-light-900 flex justify-between p-4 lg:px-5">
        <div className="my-1 h-5 w-12 bg-light-900"></div>
        <div className="my-1 h-5 w-12 bg-light-900"></div>
      </div>
    </article>
  );
}

export { ProductCard, ProductCardSkeleton };
