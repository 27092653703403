import React from 'react';

interface GStarRatingInputTypes {
  size?: number;
  rating: number;
  translateY?: string;
}

function GStarRating({
  size = 0.87,
  rating,
  translateY = '',
}: GStarRatingInputTypes): JSX.Element {
  const numberOfStars = 5;
  const iconSize = size;
  const iconSpacing = size / 3;
  const fillPercentage =
    (rating / numberOfStars) * (iconSize * numberOfStars) +
    Math.floor(rating) * iconSpacing;
  const totalWidth =
    iconSize * numberOfStars + iconSpacing * (numberOfStars - 1);

  return (
    <div className={`transform ${translateY}`}>
      <div
        className="relative"
        style={{
          width: totalWidth + 'rem',
          height: iconSize + 'rem',
          fontFamily: 'gwat !important',
        }}
      >
        <div
          className="absolute top-0 left-0 inline-flex overflow-hidden"
          style={{ width: fillPercentage + 'rem' }}
        >
          {[...Array(numberOfStars)].map((_, index) => (
            <i
              key={index}
              className="icon-star-full z-10 text-[#ffc649]"
              style={{
                width: iconSize + 'rem',
                fontSize: iconSize + 'rem',
                marginRight: iconSpacing + 'rem',
              }}
            ></i>
          ))}
        </div>
        <div
          className="absolute top-0 left-0 inline-flex"
          style={{ width: totalWidth + 'rem' }}
        >
          {[...Array(numberOfStars)].map((_, index) => (
            <i
              key={index}
              className="icon-star-outline z-10 text-[#ffc649]"
              style={{
                width: iconSize + 'rem',
                fontSize: iconSize + 'rem',
                marginRight: iconSpacing + 'rem'
              }}
            ></i>
          ))}
        </div>
      </div>
    </div>
  );
}

export { GStarRating };
