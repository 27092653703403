import { useLocation } from '@/Lib/hooks/useLocation';
import { BasicTrip } from '@/Lib/types/trip';
import { userLoginStatusAtom } from '@/State/global/global.store';
import { useAtomValue } from 'jotai';
import { currencySymbols } from 'data/currency';
import { getInternalName } from '@/Lib/helpers/get-internal-name';
import { useDynamicPrice } from '@/Lib/hooks/useDynamicPrice';
import { usePrice } from '@/Lib/hooks/usePrice';

type Props = {
  discount: number;
  trip: BasicTrip;
};

const Price = ({ discount, trip }: Props) => {
  const divisionInternalName = getInternalName(trip.division);
  const dynamicPrice = useDynamicPrice(divisionInternalName);
  const { price, discountedPrice } = usePrice({
    basePrice: trip.prices,
    dynamicPrice,
    discount,
  });

  const { currency, currencyShouldBeShown } = useLocation();

  const isUserLoggedIn = useAtomValue(userLoginStatusAtom);

  return (
    <>
      {isUserLoggedIn === true && (
        <div className="text-sm leading-3 font-semibold flex items-center justify-between flex-wrap w-full text-dark-700 space-x-1">
          {discount ? (
            <>
              <p className="text-sm space-x-1">
                <span className="text-dark-700">From</span>
                <span className="line-through text-dark-600">{price}</span>
                <span className="font-semibold text-dark-800">
                  {currencySymbols[currency]}
                  {discountedPrice}
                  {currencyShouldBeShown ? (
                    <span className="text-xs relative -top-px ml-0.5">
                      ({currency})
                    </span>
                  ) : null}
                </span>
              </p>
              {/* TODO: either remove or bring back (https://gwatco.slack.com/archives/C02JP483TTQ/p1680157902285949) */}
              {/* <span className="m-0 bg-red-500 text-red-900 rounded-full py-1 px-2 font-bold text-2xs leading-3"> */}
              {/*   {currencySymbols[currency]} */}
              {/*   {discount} off */}
              {/* </span> */}
            </>
          ) : null}
          {!discount ? (
            <p className="text-sm text-dark-700">
              From {currencySymbols[currency]}
              {price}
              {currencyShouldBeShown ? (
                <span className="text-xs relative -top-px ml-0.5">
                  ({currency})
                </span>
              ) : null}
            </p>
          ) : null}
        </div>
      )}
      {isUserLoggedIn === false && (
        <p className="text-base font-semibold text-dark-700 underline">
          See Inclusions & Pricing
        </p>
      )}
      {isUserLoggedIn === null && (
        <div className="my-1 h-5 w-12 bg-light-900  animate-pulse "></div>
      )}
    </>
  );
};

export { Price };
