import { Price } from '../types/trip';
import { useLocation } from './useLocation';

type Props = {
  basePrice: Price;
  durationPrice?: Price;
  dynamicPrice: Price | null;
  discount: number;
};

type Result = {
  price: string;
  discountedPrice: string;
};

function calculateModifiedPrice(price: Price, modifer: Price): Price {
  const newPrice: Price = {
    AUD: price.AUD + modifer.AUD,
    CAD: price.CAD + modifer.CAD,
    USD: price.USD + modifer.USD,
    GBP: price.GBP + modifer.GBP,
    EUR: price.EUR + modifer.EUR,
    NZD: price.NZD + modifer.NZD,
  };

  return newPrice;
}

const usePrice = ({
  basePrice,
  durationPrice,
  dynamicPrice,
  discount,
}: Props): Result => {
  const { currency } = useLocation();

  let price = basePrice;

  if (durationPrice && durationPrice[currency]) {
    price = durationPrice;
  }

  if (dynamicPrice) {
    const newPrice = calculateModifiedPrice(price, dynamicPrice);
    price = newPrice;
  }

  return {
    price: price[currency]?.toLocaleString(),
    discountedPrice: (price[currency] - discount)?.toLocaleString(),
  };
};

export { usePrice };
