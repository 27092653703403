import {
  toggleFavorite,
  getFavorites,
} from '@/Lib/helpers/favorites-local-storage';
import { BasicTrip, Trip } from '@/Lib/types/trip';
import React, { useEffect, useState } from 'react';
import { useLocation } from '@/Lib/hooks/useLocation';
import { CheckSessionCookie } from '@/Lib/helpers/auth0';
import { Login } from '../auth0/login';
import { useCookies } from 'react-cookie';
import { HeartSVG } from '@/Assets/icons/heart';
import { RudderAnalytics } from '../ruddarstak/rudderanalytics';
import {
  TProductAddedtoWishlist,
  TProductRemovedFromWishlist,
} from '@/DataTypes/track-events';
import { useAtom, useAtomValue } from 'jotai';
import { userLoginStatusAtom, wishlistAtom } from '@/State/global/global.store';

type FavoriteProps = {
  type: string;
  trip: BasicTrip | Trip;
  deposit: number;
  price: number;
  revenue: number;
  originalPrice: number;
  originalDeposite: number;
  isShowVideo?: boolean;
};

declare const rudderanalytics: any;

const Favorite = ({
  type,
  trip,
  deposit,
  price,
  revenue,
  originalPrice,
  originalDeposite,
  isShowVideo = false,
}: FavoriteProps) => {
  const isUserLoggedIn = useAtomValue(userLoginStatusAtom);
  const [cookies] = useCookies(['auth']);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [liked, setLiked] = useState(false);
  const { currency } = useLocation();
  const [wishlistTrigger, setWishlistTrigger] = useAtom(wishlistAtom);
  const [loading, setLoading] = useState(false);

  const { location } = useLocation();

  const rudderObj: TProductAddedtoWishlist | TProductRemovedFromWishlist = {
    currency: currency,
    content_type: 'product',
    product_name: trip.tripName,
    division: trip.division,
    destination: trip.destination,
    product_id: trip.id,
    value: price,
    deposit: deposit ? deposit : 0,
    price: price,
    original_price: originalPrice,
    original_deposit: originalDeposite,
    revenue: price,
    promotions: trip.promotions
      ? trip.promotions.map((promo) => {
          return {
            amount: promo.amount ? promo.amount[currency] : 0,
            code: promo.code,
            type: promo.type,
          };
        })
      : [],
    country: location.country.name,
  };

  const trace = (like: boolean) => {
    if (like) {
      RudderAnalytics.productAddedToWishlist(rudderObj);
    } else {
      RudderAnalytics.productRemovedFromWishlist(rudderObj);
    }
  };

  useEffect(() => {
    const favorites = getFavorites();
    if (favorites.findIndex((item) => item === trip.id) > -1) {
      setLiked(true);
    } else {
      setLiked(false);
    }
  }, [trip.id, wishlistTrigger]);

  const toggleLike = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (isUserLoggedIn) {
      AfterLogin();
    } else {
      setIsModalOpen(true);
    }
  };

  const AfterLogin = () => {
    toggleFavorite(trip.id);
    trace(!liked);
    setLiked(!liked);
    setWishlistTrigger((p) => p + 1);
  };

  return (
    <>
      <Login
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        AfterLogin={AfterLogin}
        type="wishlist-init-form"
      />
      {loading ? (
        <>
          {' '}
          <div
            className={
              'hidden z-20  absolute  top-5 bg-red-900 ' +
              (type != 'product' ? '' : 'md:block') +
              (isShowVideo ? ' right-[4.375rem]' : ' left-5')
            }
          >
            <div className="button-load">
              <span className="load-circle-favorite"></span>
            </div>
          </div>
          <div
            className={
              'absolute w-7 h-7 ' +
              (type != 'product' ? '' : 'md:hidden ') +
              (type == 'smallcard' ? ' right-0.5 top-0.5' : ' right-3 top-3')
            }
          >
            <div className="button-load">
              <span className="load-circle-favorite-mobile"></span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={
              'hidden z-20 group bg-light-600 w-10 h-10 rounded-full shadow-btn absolute top-5 cursor-pointer overflow-hidden transition-width duration-300 transform  ' +
              (liked ? 'hover:w-48 ' : 'hover:w-36 ') +
              (type != 'product' ? '' : 'md:block') +
              (isShowVideo ? ' right-5' : ' left-5')
            }
            onClick={toggleLike}
          >
            <div className="flex w-10 h-10 justify-center items-center float-left ">
              <HeartSVG
                className={
                  'w-7 h-7 ' +
                  (liked
                    ? 'animate-beat fill-current text-[#ec334f] '
                    : 'stroke-current text-dark-800 ')
                }
              />
            </div>

            <div className="flex whitespace-nowrap h-10 items-center pr-2 text-sm text-dark-800  opacity-0 transition-all duration-300  group-hover:opacity-100">
              <span className="font-semibold">
                {liked ? 'Remove from wishlist' : 'Add to wishlist'}
              </span>
            </div>
          </div>
          <div
            className={
              'z-20 w-7 h-7 absolute cursor-pointer ' +
              (type != 'product' ? '' : 'md:hidden ') +
              (type == 'smallcard' ? ' right-0.5 top-0.5' : ' right-3 top-3')
            }
            onClick={toggleLike}
          >
            <HeartSVG
              className={
                'w-7 h-7 ' +
                (liked
                  ? 'animate-beat stroke-light stroke-[0.094rem]  fill-current text-[#ec334f] '
                  : 'stroke-light stroke-[0.094rem] fill-current text-[#00000066]')
              }
            />
          </div>
        </>
      )}
    </>
  );
};
export default Favorite;
