import { CookieHelper } from '../helpers/cookie';
import { Flags } from '../types/flags';

const FLAGS_COOKIE_NAME = 'x-flags';

const useFlags = () => {
  let flags: Flags | null = null;

  if (typeof document !== 'undefined') {
    const cookieString = CookieHelper.getByName(FLAGS_COOKIE_NAME);
    try {
      if (cookieString.length > 0) {
        flags = JSON.parse(cookieString) as any;
      }
    } catch (e) {
      console.error('Error: Parsing flags cookie failed.');
    }
  }

  return flags;
};

export { useFlags };
