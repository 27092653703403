import { Price } from '../types/trip';
import { useFlags } from './useFlags';

const useDynamicPrice = (division: string): Price | null => {
  const flags = useFlags();
  console.log({ flags });
  const flagId = `price:${division}`;
  const price = flags && flags[flagId];

  if (price && price.enabled && price.value) {
    try {
      const parsed = JSON.parse(price.value as string);
      for (let key of Object.keys(parsed)) {
        parsed[key] = Number(parsed[key]);
      }
      console.log({ parsed });
      return parsed as Price;
    } catch (e) {
      return null;
    }
  }

  return null;
};

export { useDynamicPrice };
